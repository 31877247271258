div.App {
  background-color: whitesmoke;
  text-align: center;
  height: 100vh;
  font-family:  'Roboto', sans-serif;
}

img.App-logo {
  height: 50px;
  width: 50px;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

/* Menu Buttons */
div.btn-application-main-menu.nav-item {
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  white-space: pre-wrap;

  --b: 3px;   /* border thickness */
  --s: .45em; /* size of the corner */
  --color: white;
  
  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

div.btn-application-main-menu.nav-item:hover,
div.btn-application-main-menu.nav-item:focus-visible{
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
  cursor: pointer;
}

div.btn-application-main-menu.nav-item:active {
  background: var(--color);
  color: #fff;
}





.application-main-menu-container.container{
  background-color: transparent;
  max-width: 100%;
  padding: 0px 15px;
}


span.stm-main-menu-header {
  background-color: transparent;
  padding-right: 15px;
}

h1.stm-main-menu-header {
  background-color: transparent;
  color: whitesmoke;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  margin: 4px 2px;
}

div.application-main {
  background-color: whitesmoke;
  color: black;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

div.application-main-body {
  background-color: whitesmoke;
  color: black;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

div.application-eula-privacy {
  margin-top: 25px;
}

p.eula-body {
  background-color: transparent;
  color: black;
  border: none;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  white-space: pre-wrap;
  width: 75%;
}

p.privacy-body {
  background-color: transparent;
  color: black;
  border: none;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  white-space: pre-wrap;
  width: 75%;
}


/* CSS */
.button-89 {
  --b: 3px;   /* border thickness */
  --s: .45em; /* size of the corner */
  --color: #373B44;
  
  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-89:hover,
.button-89:focus-visible{
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}

.button-89:active {
  background: var(--color);
  color: #fff;
}